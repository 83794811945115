/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// Lib dependencies
import { useTranslation } from 'react-i18next';

// Components
import LoadingOverlay from '../../../shared/components/organisms/LoadingOverlay';

// Configs
import languages from '../../configs/languages.configs';

// Services
import IpServices from '../../../shared/services/ip.service';

// Others
import useStore from '../../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const LanguageProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const [isLoading, setIsLoading] = useState(true);

  const { children } = props;

  const { i18n } = useTranslation();

  const { loadUser } = useStore();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const detectLanguage = async () => {
      try {
        const response = await IpServices.get();

        loadUser(response);

        const { countryCode } = response;

        if (countryCode === 'CN' || countryCode === 'HK') {
          const UID = searchParams.get('UID');

          let redirectUrl = process.env.REACT_APP_CN_URL;

          if (UID) {
            redirectUrl += `?UID=${UID}`;
          }

          window.location.href = redirectUrl;
          return;
        }

        if (countryCode in languages && languages[countryCode] !== i18n.language) {
          // Switch to the detected language
          i18n.changeLanguage(languages[countryCode]);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    detectLanguage();
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default LanguageProvider;
